<template>
  <div class="flex w-full justify-center">

    <div class="p-10 content-container max-w-screen-xl">

      <div class="title-container w-full flex justify-between sm-centered items-center">
        <img src="@/assets/logo-menus.svg" alt="menus logo" class="h-24 menu-title-logo"/>
        <button @click="() => $router.push('/signup')" class="btn-primary sm-hidden">Get an awesome menu today!</button>
      </div>

      <div class="flex lg-wrap main-container" style="margin-top: 60px;">
        <section>
          <h1 class="header-text-size">Your customers deserve a mobile menu experience that doesn't suck.</h1>
          <div class="copy-container lg-no-max-width">
            <div class="info-block">
              <div class="flex">
                <h2>Mobile menus should be optimized for <img src="@/assets/icon-phone.svg" aria-hidden="true" alt="icon phone" class="rotated-text-icon inline-text-icon h-10 mt-2"><span class="text-orange">mobile</span></h2>
              </div>
              <p>
                <strong>No more annoying pinching and zooming!</strong> In Order.ly menus, the text is immediately legible and you can
                browse through them like you would a good website.
              </p>
            </div>
            <div class="info-block">
              <div class="flex">
                <h2>Mobile menus should be easy to navigate</h2>
              </div>
              <p><strong>Small screen, large menu – big problems.</strong> In Order.ly menus you can jump between sections easily. No
                more endless scrolling. Fly past the salads and get straight to the desserts!</p>
            </div>
            <div class="info-block">
              <div class="flex">
                <h2>All your menus, one <br style="display: none;" class="sm-show"> <img aria-hidden="true" src="@/assets/icon-QR.svg" alt="icon phone" class="inline-text-icon h-10 pr-2"><span class="text-orange">QR code</span></h2>
              </div>
              <p class="mt-1"><strong>Breakfast, lunch, dinner... and cocktails!</strong> Have all your menus available via one QR code. Show your
                customers a list of menus to choose from, or load a particular menu based on time of day.</p>
            </div>
            <div class="info-block">
              <div class="flex">
                <h2>You’re busy. We’ll build your menu for you.</h2>
              </div>
              <p><strong>Who has time to type in a whole menu?!</strong> Not you. Just send us your menu as a PDF, link, or even some
                clear pictures, and our caffeine-powered specialists will get your menu set up in no time.</p>
            </div>
            <div class="lower-cta-button-wrapper">
              <button @click="() => $router.push('/signup')" style="margin-top: 40px" class="btn-primary">Get an awesome menu today!</button>
            </div>
          </div>
        </section>
        <div class="p-4 h-full flex flex-grow items-center justify-center video-container">
          <VideoPreview />
        </div>
      </div>

      <div class="flex relative sm-hidden lg-col demo-container" style="margin-top: 160px">
        <img src="@/assets/demo.svg"
             alt="An example of a small information card containing a QR code that a customer could use to navigate to one of our menus" />
        <div class="flex flex-col items-center step-container lg-flex lg-block">
          <h2>Scan the sign to see it yourself</h2>
          <div class="flex justify-around items-end lg-auto-width" style="width: 490px; margin-top: 64px">
            <div class="step">
              <img alt="An icon of a mobile phone" aria-hidden="true" src="@/assets/icon-phone.svg" />
              <h3>step 1</h3>
              <p>Open camera app</p>
            </div>
            <div class="step">
              <img alt="An icon of a QR code" aria-hidden="true" src="@/assets/icon-QR.svg" />
              <h3>step 2</h3>
              <p>Scan QR code</p>
            </div>
            <div class="step">
              <img alt="An icon of a fork and knife" aria-hidden="true" src="@/assets/icon-silverware.svg" />
              <h3>step 3</h3>
              <p>View menu and order</p>
            </div>
          </div>
          <div class="flex flex-col items-center mt-8">
            <span class="or-text">- Or -</span>
            <button @click="$router.push('/demo')" class="btn-outlined mt-4">
              Click Here
            </button>
          </div>
        </div>
      </div>
      <h1 class="w-full text-center mt-20 header-text-size">Pricing you can't miss</h1>
      <div class="pricing flex flex-row mt-6 w-full justify-center flex-wrap">
        <PricingCard
          header="monthly plan"
          price="$29"
          epoch="per month"
          :perks="['Hassle-free, done-for-you-setup', 'Unlimited menu updates', 'Your own branding']"
          button-text="Choose monthly plan"
          :click="() => $router.push({ path: '/signup', query: { plan: 'monthly' }})"
        />
        <PricingCard
          header="annual plan"
          price="$290"
          epoch="per year"
          deal="Save 17% over monthly plan"
          :perks="['Hassle-free, done-for-you-setup', 'Unlimited menu updates', 'Your own branding', '10 free tabletop signs and stands']"
          button-text="Choose annual plan"
          :click="() => $router.push({ path: '/signup', query: { plan: 'annual' }})"
        />
      </div>
      <div>
        <OrderlyCurbsideTeaser class="my-32" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import VideoPreview from "@/components/VideoPreview";
import Footer from "@/components/Footer";
import PricingCard from "@/components/PricingCard";
import OrderlyCurbsideTeaser from "@/components/OrderlyCurbsideTeaser";

export default {
  name: 'home',
  components: {
    OrderlyCurbsideTeaser,
    PricingCard,
    VideoPreview,
    Footer
  }
}
</script>

<style scoped>

.step img {
  height: 80px;
}

.demo-container {
  transform: translateX(-175px);
}

.rotated-text-icon {
  transform: translateY(-10px) rotate(15deg);
}

.inline-text-icon {
  display: inline;
}

.header-text-size {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
}



.copy-container {
  max-width: 600px;
}

.info-block {
  margin-top: 24px;
}

.text-orange {
  color: var(--menus-orange)
}

.btn-primary:hover {
  background: #E56A25;
  transform: translateY(-4px);
  box-shadow: 2px 6px 14px rgba(229, 67, 37, 0.25)
}

.btn-primary:active {
  transform: translateY(0);
  background: var(--menus-orange);
  box-shadow: var(--shadow-orange);
  transition: all .10s
}

.step h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-transform: none;
  margin-top: 16px;
}

.step-container {
  position: absolute;
  left: 718px;
  top: 82px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.or-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (max-width: 1080px) {

  .lg-col {
    display: flex;
    flex-direction: column;
  }

  .lg-flex {
    display: flex!important;
  }

  .lg-block {
    position: relative;
  }

  .lg-wrap {
    flex-direction: column;
  }

  .step-container {
    position: relative;
    left: unset;
    bottom: unset;
    top: unset;
  }

  .demo-container {
    transform: translateX(0px);
  }

  .video-container {
    margin-top: 60px;
  }

  .lg-auto-width {
    width: auto;
  }

  .lg-no-max-width {
    max-width: none;
  }

}

@media only screen and (max-width: 620px) {

  .pricing >>> .pricing-card {
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .sm-show {
    display: block!important;
  }

  .sm-hidden {
    display: none!important;
  }

  .menu-title-logo {
    width: 131.42px;
    height: 80px;
  }

  .sm-centered {
    justify-content: center!important;
    align-items: center;
  }

  h1.header-text-size {
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
    text-align: left;
    color: #000000;
  }

  div.main-container {
    margin-top: 24px!important;
  }

  .content-container {
    padding: 20px!important;
    max-width: 100%!important;
  }

  .video-container {
    padding: 0!important;
  }

  .lower-cta-button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-block h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }

}

</style>
