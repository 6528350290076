<template>
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1L6 12L1 7" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconPerkCheck"
}
</script>

<style scoped>

</style>
