<template>
  <div class="main-container">
    <video  class="video" style="border-radius: 32px;" muted autoplay loop playsinline>
      <source
        src="https://429430c83d2345b107df-f0d52643f00db9e85e2467d160bacbc2.ssl.cf5.rackcdn.com/DbqOOLxWRw2vzDRXQOZLzg/demo-edited.mp4"
        type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoPreview",
  data() {
    return {
      height: 305
    }
  }
}
</script>

<style scoped>

.main-container {
  max-width: 360px;
  width: 360px;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
}
</style>
