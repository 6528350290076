<template>
  <div class="pricing-card">
    <link href="https://fonts.googleapis.com/css2?family=Caveat&display=swap" rel="stylesheet">
    <h3 class="mt-8">{{ header }}</h3>
    <span class="price">{{ price }}</span>
    <span>{{ epoch }}</span>
    <div class="deal-container">
      <span class="bonus">{{ deal }}</span>
    </div>
    <div class="perk-container mt-6">
      <div class="flex items-center" v-for="perk in perks" :key="perk">
        <IconPerkCheck class="mr-2"/>
        <h4 class="perk-text my-2">{{ perk }}</h4>
      </div>
    </div>
    <div class="flex-grow"/>
    <FormButton :click="click" class="button mb-8" :text="buttonText"/>
  </div>
</template>

<script>
import IconPerkCheck from "@/components/icons/IconPerkCheck";
import FormButton from "@/components/form/FormButton";

export default {
  name: "PricingCard",
  components: {FormButton, IconPerkCheck},
  props: {
    header: {
      default: 'monthly plan',
      type: String
    },
    price: {
      default: '$29',
      type: String
    },
    epoch: {
      default: 'per month',
      type: String,
    },
    deal: {
      default: '',
      type: String,
    },
    perks: {
      default: () => ['Hassle-free, done-for-you setup', 'Unlimited menu updates'],
      type: Array,
    },
    buttonText: {
      default: 'Choose monthly plan',
      type: String
    },
    click: {
      default: () => { console.error('need to override click handler') },
      type: Function,
    }
  }
}
</script>

<style scoped>

.pricing-card {
  height: 475px;
  width: 420px;
  background: white;
  margin: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.price {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  color: #000000;
}

.button {
  width: calc(100% - 48px);
}

.deal-container {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.perk-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--gray-700);
}

.bonus {
  font-family: 'Caveat', cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: var(--menus-orange);
}

</style>
