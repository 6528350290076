<template>
  <div class="relative">
    <IconComingSoon class="soon absolute w-full md:w-auto md:left-0 md:ml-6" />
    <div class="teaser-container flex flex-col md:flex-row justify-around py-6">
      <div class="content w-full flex flex-col items-center md:items-start md:ml-6 mt-8">
        <IconOrderlyLogo/>
        <h2 class="header text-center md:text-left">Curbside pickup made easy</h2>
        <p class="copy hidden md:block">
          Orderly is the easiest way to do curbside pickups for both customers and business owners. While other delivery
          apps take away huge margins from you, we only take away the hassle.
        </p>
      </div>
      <div class="flex w-full md:w-auto justify-center">
        <IconDeliveryDemo class="mt-6"/>
      </div>
    </div>
  </div>
</template>

<script>
import IconOrderlyLogo from "@/components/icons/IconOrderlyLogo";
import IconDeliveryDemo from "@/components/icons/IconDeliveryDemo";
import IconComingSoon from "@/components/icons/IconComingSoon";

export default {
  name: "OrderlyCurbsideTeaser",
  components: {IconComingSoon, IconDeliveryDemo, IconOrderlyLogo}
}
</script>

<style scoped lang="scss">

.teaser-container {
  background: #F3FAFF;
  border-radius: 12px;
}

h2.header {
  font-family: 'Sansita', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: black;
}

p.copy {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}

.soon {
  transform: translateY(-35px);
  font-family: Shutter Stone, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 80px;
  line-height: 85px;
  color: var(--menus-orange);
}

.content {
  max-width: unset;
}

@screen md {
  .content {
    max-width: 500px;
  }
  .soon {
    transform: translateY(-60px)translateX(82px);
    height: 100px;
  }
}

</style>
